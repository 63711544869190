.sidebar__col {
  max-width: 300px;
}
.sidebar__wrapper {
  color: $white;
  > .row {
    background-color: $dark-gray;
  }
  .sidebar__profile-info__wrapper {
    border-bottom: 2px solid $main-color;
    padding-top: 30px;
    margin-bottom: 20px;
    .box__info__icon {
      width: 40px;
      height: 40px;
      border-radius: 40px;
      background-color: $main-color;
      overflow: inherit;
      margin: 0 auto;
      position: relative;
      display: inline-block;
      margin-bottom: 30px;
      margin-right: 5px;
      &:last-child {
        margin-right: 0;
      }
      a {
        display: block;
        text-decoration: none;
        > img {
          position: absolute;
          top: 7px;
          left: 10px;
        }
        .image__budget-wrapper {
          width: 20px;
          height: 20px;
          border-radius: 20px;
          background-color: $red;
          position: absolute;
          left: auto;
          right: -5px;
          top: -5px;
          .image__budget-number {
            @include font($font-family-main,0.5em,$normal);
            color: $white;
            position: absolute;
            top: 5px;
            left: 0;
            width: 100%;
            text-align: center;
          }
        }
      }
      &.box__info__general {
        > a > img {
          top: 8px;
          left: 15px;
        }
      }
      &.box__info__logout {
        > a > img {
          top: 12px;
          left: 11px;
        }
      }
    }
    .box__info__image {
      width: 158px;
      height: 158px;
      border-radius: 158px;
      overflow: hidden;
      border: 4px solid $main-color;
      margin: 0 auto 15px;
    }
    .box__info__name {
      margin-bottom: 20px;
      p {
        @include font($font-family-main,1.2em,$normal);
        color: $white;
        margin-bottom: 15px;
      }
    }
    .box__info__extra-data {
      margin-bottom: 30px;
      .extra-data__points-text,
      .extra-data__ranking-text {
        p {
          @include font($font-family-main,1em,$normal);
          color: $white;
          margin-bottom: 5px;
        }
      }
      .extra-data__points,
      .extra-data__ranking {
        p {
          @include font($font-family-bold,1.5em,$bold);
          color: $white;
          margin-bottom: 10px;
          span {
            font-size: 0.7em;
          }
          img {
            vertical-align: inherit;
          }
        }
      }
    }
  }
  .sidebar__profile-team__wrapper {
    border-bottom: 2px solid $main-color;
    padding-top: 10px;
    padding-bottom: 30px;
    margin-bottom: 20px;
    .box__team__carousel {
      .carousel-indicators {
        position: absolute;
        top: auto;
        bottom: -30px;
        left: 0;
        right: auto;
        height: 25px;
        width: 100%;
        margin: 0 auto;
        li {
          background-color: $white;
        }
        li.active {
          background-color: $main-color;
        }
      }
    }
    .box__info__image {
      width: 80px;
      height: 80px;
      border-radius: 80px;
      background-color: $main-color;
      overflow: hidden;
      position: relative;
      margin: 0 auto 15px;
    }
    .box__info__name {
      margin: 0 auto 20px;
      p {
        @include font($font-family-main,1.2em,$normal);
        color: $white;
        margin-bottom: 0;
      }
    }
    .box__info__extra-data {
      .extra-data__points-text,
      .extra-data__ranking-text {
        p {
          @include font($font-family-main,1em,$normal);
          color: $white;
          margin-bottom: 5px;
        }
      }
      .extra-data__points,
      .extra-data__ranking {
        p {
          @include font($font-family-bold,1.5em,$bold);
          color: $white;
          margin-bottom: 10px;
          span {
            font-size: 0.7em;
          }
          img {
            vertical-align: inherit;
          }
        }
      }
    }
  }
  .sidebar__title__wrapper {
    margin-bottom: 15px;
    .title__text {
      @include font($font-family-bold,1.2em,$bold);
      color: $white;
      text-align: center;
      margin-bottom: 0;
    }
    .help__text {
      @include font($font-family-main,0.7em,$normal);
      color: $white;
      text-align: center;
    }
  }
  .sidebar__profile-social-network__wrapper {
    border-bottom: 2px solid $main-color;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    .social-network-tokens__wrapper {
      width: 100%;/*30+5+30+5+30*/
      margin: 0 auto;
      text-align: center;
      .box__info__social-network {
        width: 30px;
        overflow: hidden;
        margin: 0 auto;
        display: inline-block;
        a {
          display: block;
          margin-right: 5px;
          img {

          }
        }
        &:last-child {
          a {
            margin-right: 0;
          }
        }
      }
    }
  }
  .sidebar__profile-activity__wrapper {
    border-bottom: 2px solid $main-color;
    padding-top: 10px;
    padding-bottom: 30px;
    margin-bottom: 20px;
    .box__info__text__statistics {
      margin-top: 15px;
      .statistics__label {
        @include font($font-family-main,0.8em,$normal);
        color: $white;
        margin-bottom: 0;
        text-align: center;
      }
      .statistics__number {
        @include font($font-family-bold,1.5em,$bold);
        color: $white;
        margin-bottom: 0;
        text-align: center;
      }
    }
  }
  .sidebar__profile-privacity__wrapper {
    a {
      text-align: center;
      text-decoration: none;
      display: block;
      @include font($font-family-main,1em,$normal);
      color: $white;
      &:link,
      &:active,
      &:visited,
      &:hover {
        color: $white;
      }
    }
  }
}

#side-nav {
  @media #{$breakResponsive} {
    height: 100%;
    width: 0;
    z-index: 1;
    top: 0;
    left: 0;
    overflow: hidden;
    transition: 0.5s;
    padding-left: 0;
    padding-right: 0;
  }
}
#content-nav {
  @media #{$breakResponsive} {
    transition: 0.5s;
  }
}