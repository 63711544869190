.dashboard__wrapper {
  margin: 60px auto 40px;
  .dashboard__wrapper__agreement {
    margin-bottom: 2em;
    p {
      margin-bottom: 0;
    }
    .dashboard__wrapper__agreement-main {
      @include font($font-family-bold,2.2em,$bold);
    }
    .dashboard__wrapper__agreement-secondary {
      @include font($font-family-main,1.3em,$normal);
    }
  }
  .dashboard__wrapper__boxes {
    .filled {
      .box-info__wrapper {
        background-color: $main-color;
      }
    }
    .box-info__wrapper {
      position: relative;
      padding: 35px 10px;
      margin: 30px auto 70px;
      text-align: center;
      @media #{$breakResponsive} {
        margin-bottom: 30px;
      }
      .box__info__label {
        position: absolute;
        top: -12px;
        left: 20px;
        background-color: $black;
        color: $white;
        padding: 3px 15px;
        p {
          @include font($font-family-bold,1em,$bold);
          color: $white;
          margin-bottom: 0;
        }
      }
      .box__info__image {
        width: 158px;
        height: 158px;
        border-radius: 158px;
        overflow: hidden;
        border: 4px solid $white;
        margin: 0 auto 15px;
      }
      .box__info__name {
        margin-bottom: 20px;
        border-bottom: 2px solid $white;
        p {
          @include font($font-family-main,1em,$normal);
          color: $black;
          margin-bottom: 15px;
        }
      }
      .box__info__extra-data {
        .extra-data__points-text,
        .extra-data__ranking-text {
          p {
            @include font($font-family-main,1em,$normal);
            color: $black;
            margin-bottom: 5px;
          }
        }
        .extra-data__points,
        .extra-data__ranking {
          p {
            @include font($font-family-bold,1.5em,$bold);
            color: $black;
            margin-bottom: 10px;
            span {
              font-size: 0.7em;
            }
            img {
              vertical-align: inherit;
            }
          }
        }
      }
    }
  }
}
