.prize__wrapper {

}

.prize__filter-form {

}

.prize__collection__wrapper {
  .prize__collection__header__wrapper {
    background-color: $dark-gray;
    padding: 20px 30px;
    text-align: center;
    .prize__title__wrapper {
      border-bottom: 2px solid $main-color;
      margin-bottom: 20px;
      padding-bottom: 20px;
      .prize__title {
        text-align: center;
        margin: 0 auto;
        color: $main-color;
        @include font($font-family-bold,1em,$bold);
      }
    }
    .ranking__filter__wrapper {
      color: $black;
      border: none;
      border-radius: 40px;
      overflow: hidden;
      background: #EFEFEF url("../static/images/icono-flecha-grande.svg") no-repeat 90% center;
      outline: none;
      max-width: 230px;
      margin: 0 auto;
      select {
        padding: 5px 8px;
        width: 100%;
        height: 100%;
        line-height: 30px;
        border: none;
        box-shadow: none;
        background-color: transparent;
        background-image: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
    }
  }
  .prize__collection__content__wrapper {
    background-color: $almost-white;
    padding: 30px;
    text-align: center;
    margin-bottom: 30px;
    @media #{$breakResponsive} {
      padding-bottom: 0;
    }
    .prize__box__wrapper {
      margin-bottom: 30px;
      .prize__label__wrapper {
        position: absolute;
        padding: 4px 20px;
        top: -10px;
        left: 30px;
        background-color: $main-color;
        p {
          @include font($font-family-bold,1em,$bold);
          color: $black;
          margin-bottom: 0;
        }
      }
      .prize__image__wrapper {
        height: 180px;
        overflow: hidden;
        width: auto;
        background-color: $black;
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
      .prize__content__wrapper {
        background-color: $white;
        padding: 20px;
        height: 170px;
        overflow: hidden;
        .content__title {
          @include font($font-family-bold,1.8em,$bold);
          color: $black;
          margin: 0 auto 10px;
          text-align: center;
        }
        .content__description {
          @include font($font-family-bold,1em,$bold);
          color: $black;
          margin-bottom: 10px;
        }
      }
    }
  }
}