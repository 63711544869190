.main-menu {
  border-radius: 0;
  border: 0;
  background-color: $black;
  border-bottom: 4px solid $main-color;
  margin-bottom: 0;
  .main-menu__header {
    a {
      padding: 5px 15px;
      img {
        max-width: 60%;
      }
    }
  }
  .navbar-collapse {
    li {
      color: $white;
      @include font($font-family-bold,1em,$bold);
      a {
        color: $white;
      }
      &:after {
        content: "|";
        color: $main-color;
        position: absolute;
        top: 28%;
        right: 0;
      }
      &:last-child:after {
        content: "";
      }
    }
    li.active {
      color: $main-color;
      background: transparent;
      a {
        color: $main-color;
        background: transparent;
      }
    }
  }
}