.box-info-v2__wrapper {
  position: relative;
  border: 5px solid $main-color;
  padding: 35px 20px;
  margin: 0 auto 30px;
  text-align: center;
  height: 135px;
  .box__info__label {
    position: absolute;
    top: -12px;
    left: 20px;
    background-color: $main-color;
    color: $black;
    padding: 3px 15px;
    p {
      @include font($font-family-bold,1em,$bold);
      color: $black;
      margin-bottom: 0;
    }
  }
  .box__info__image {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background-color: $main-color;
    overflow: inherit;
    margin: 0 auto;
    position: relative;
    > img {
      position: absolute;
      top: 12px;
      left: 15px;
    }
    .image__budget-wrapper {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background-color: $red;
      position: absolute;
      left: auto;
      right: -5px;
      top: -5px;
      .image__budget-number {
        @include font($font-family-main,0.5em,$normal);
        color: $white;
        position: absolute;
        top: 5px;
        left: 0;
        width: 100%;
        text-align: center;
      }
    }
  }
  .box__info__text {
    text-align: left;
    p {
      @include font($font-family-bold,1.6em,$bold);
      color: $black;
      margin-bottom: 0;
      a {
        color: $black;
        text-decoration: none;
        &:hover,
        &:active,
        &:visited,
        &:link {
          color: $black;
        }
      }
    }
  }
}