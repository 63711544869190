/**
Change Bootstrap v3 navbar breakpoint
 */
@media #{$breakResponsive} {
  .navbar-header {
    float: none;
  }
  .navbar-left,.navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar-nav {
    float: none!important;
    margin-top: 7.5px;
  }
  .navbar-nav>li {
    float: none;
  }
  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in{
    display:block !important;
  }
}

@media #{$breakResponsive} {
  .navbar-header {
    float: left;
    width: 83.33333%;
    margin-left: 16.66667%;
  }
  .navbar-brand {
    display: block;
    width: 80%;
    > img {
      max-width: 35%;
      width: 35%;
      margin: 0 auto;
    }
  }
  .main-menu .navbar-collapse {
    width: 30%;
    position: absolute;
    z-index: 1;
    background-color: #000;
    top: 55px;
    left: auto;
    right: 5px;
    ul {
      text-align: center;
      li {
        &:after {
          content: "";
        }
      }
    }
  }
  #triggerSideNav {
    z-index: 1;
    float: left;
    height: 100%;
    padding-top: 1%;
    position: absolute;
    top: 0;
    left: 0;
    #icon-sidenav {
      background: #000 url("../static/images/icono-dashboard-blanco.svg") no-repeat 100% center;
      width: 50px;
      height: 30px;
      display: block
    }
    #icon-sidenav.opened {
      background: #000 url("../static/images/icono-dashboard-amarillo.svg") no-repeat 100% center;
    }
  }
}