.front__body {
  @include font($font-family-main,$size,$normal);
  background-color: $black;
  color: $white;
  header {
    border-bottom: 4px solid $main-color;
    padding-bottom: 1em;
    margin-bottom: 3em;
    .main__title {
      font-size: 0;
      img {
        display: inline;
      }
    }
  }
  .main__content {

  }
  .login-azure__wrapper {
    .login-azure__agreement {
      margin: 1em auto 2em;
      .login-azure__agreement-main {
        @include font($font-family-bold,2.2em,$bold);
      }
      .login-azure__agreement-secondary {
        @include font($font-family-main,1.3em,$normal);
      }
    }
    .login-azure__form {
      .login-azure__form-box {
        padding: 2em;
        background-color: $white;
        .btn-custom {
          display: block;
          color: $black;
          background-color: $main-color;
          border: 0;
          border-radius: 4em;
        }
      }
    }
  }
}