.box__activity {
  .box__info__text__statistics {
    text-align: center;
    .statistics__label {
      @include font($font-family-main,1em,$normal);
      color: $black;
      margin-bottom: 0;
      text-align: center;
    }
    .statistics__number {
      @include font($font-family-bold,1.5em,$bold);
      color: $black;
      margin-bottom: 0;
      text-align: center;
    }
  }
  .box__info_help-text {
    position: absolute;
    bottom: -25px;
    left: auto;
    right: 0;
    p {
      @include font($font-family-main,0.7em,$normal);
      color: $black;
      margin-bottom: 0;
    }
  }
}