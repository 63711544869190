.blank__page {
  background-color: $white;
  padding: 40px 40px;
  text-align: left;
  .title-page__wrapper {
    margin-bottom: 40px;
    text-align: left;
    @media #{$breakResponsive} {
      text-align: center;
    }
    .page__title {
      @include font($font-family-bold,2em,$bold);
      color: $black;
      margin-bottom: 0;
      text-align: left;
      @media #{$breakResponsive} {
        text-align: center;
      }
    }
    .page__subtitle {
      @include font($font-family-main,1.3em,$normal);
      color: $black;
      margin-bottom: 0;
      text-align: left;
      @media #{$breakResponsive} {
        text-align: center;
      }
    }
  }
  .content-page__wrapper {
    > h1,h2,h3,h4,h5,h6 {
      @include font($font-family-bold,1.3em,$bold);
      color: $black;
      margin: 30px 0 0;
      text-align: left;
    }
    > p {
      @include font($font-family-main,1em,$normal);
      color: $black;
      margin-bottom: 0;
      text-align: justify;
    }
  }
}