.profile__form {
  margin-bottom: 50px;
}
.profile__form__avatar {
  .profile__form__avatar-default {
    background-color: $almost-white;
    border: 4px solid $main-color;
    border-radius: 10em;
    width: 10em;
    height: 10em;
    overflow: hidden;
    margin: 0 auto 2em;
    img {
      display: block;
      margin: 0 auto;
      padding-top: 2em;
    }
  }
}
.profile__form__text__wrapper {
  margin-bottom: 2em;
  p {
    margin-bottom: 0;
  }
  .profile__form__text__main {
    @include font($font-family-bold,2.2em,$bold);
  }
  .profile__form__text__help {
    @include font($font-family-main,1.3em,$normal);
  }
}
.profile__form__body {
  padding: 3em 2em;
  background-color: $white;
  .field {
    margin-bottom: 1em;
    .field__label-wrapper,
    .field__select-wrapper{
      height: 3em;
      line-height: 3em;
    }
  }
  .field__label-wrapper {
    text-align: left;
    @media #{$breakResponsive} {
      text-align: center;
    }
    label {
      color: $black;
      @include font($font-family-bold,1em,$bold);
      margin-bottom: 0;
    }
  }
  .field__select-wrapper {
    color: $black;
    border: none;
    border-radius: 40px;
    overflow: hidden;
    background: #EFEFEF url("../static/images/icono-flecha-grande.svg") no-repeat 90% center;
    outline: none;
    @media #{$breakResponsive} {
      background: #EFEFEF url("../static/images/icono-flecha-grande.svg") no-repeat 95% center;
    }
    select {
      padding: 5px 8px;
      width: 100%;
      height: 100%;
      line-height: 30px;
      border: none;
      box-shadow: none;
      background-color: transparent;
      background-image: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
  }
  .field__input-wrapper {
    position: relative;
    @media #{$breakResponsive} {
      margin-bottom: 10px;
    }
    img {
      display: inline-block;
      position: absolute;
      top: 5px;
      left: 22px;
    }
    img.tick-icon {
      display: inline-block;
      position: absolute;
      right: 30px;
      left: auto;
      top: 14px;
    }
    input {
      display: inline-block;
      width: 100%;
      background-color: #EFEFEF;
      border: 0;
      height: 40px;
      line-height: 40px;
      border-radius: 40px;
      box-sizing: border-box;
      padding-left: 45px;
      color: $black;
      &::placeholder,
      &:-ms-input-placeholder,
      &::-ms-input-placeholder {
        color: $gray;
      }
    }
  }
  .btn-custom {
    display: block;
    color: $black;
    background-color: $main-color;
    border: 0;
    border-radius: 40px;
    width: 100%;
    height: 40px;
    line-height: 30px;
    &[disabled] {
      background-color: $gray;
    }
  }
}
.profile__form__buttons__wrapper {
  margin-top: 2em;
}
.profile__wrapper .content-page__wrapper {
  padding-left: 15px;
  padding-right: 15px;
}

#front-profile .main__content {
  background-color: $almost-white;
  .blank__page {
    background-color: transparent;
  }
}