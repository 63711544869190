.social-room__wrapper {
  .social-room-object__wrapper {
    height: 421px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  .social-room-object__image__wrapper {
    height: 184px;
    overflow: hidden;
    width: auto;
    background-color: $black;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .social-room-object__content__wrapper {
    background-color: $almost-white;
    position: relative;
    padding: 35px 30px 20px;
    min-height: 237px;
    .content__company__icon__wrapper {
      position: absolute;
      width: 40px;
      height: 40px;
      overflow: hidden;
      border-radius: 40px;
      top: -20px;
      left: 20px;
      border: 1px solid $almost-white;
      img {
        max-width: 40px;
        height: auto;
      }
    }
    .content__text__wrapper {
      text-align: left;
      margin-bottom: 20px;
      min-height: 70px;
      .text__paragraph {
        @include font($font-family-main,1em,$normal);
        color: $black;
        margin-bottom: 0;
      }
    }
    .content__time__wrapper {
      margin-bottom: 11px;
      text-align: left;
      .time__social-network__icon__wrapper {
        display: inline-block;
        margin-right: 5px;
      }
      .time__text__wrapper {
        display: inline-block;
        .text__paragraph {
          @include font($font-family-main,0.85em,$normal);
          color: $black;
          margin-bottom: 0;
        }
      }
    }
    .content__share-button__wrapper {
      form {}
      .share-button__button {
        input[type="submit"] {
          display: block;
          color: $black;
          background-color: $main-color;
          border: 0;
          border-radius: 4em;
          width: 100%;
          height: 40px;
        }
      }
    }
  }
}

.social-network__filter-form {
  margin-bottom: 40px;
  [name="social-network__select"] {
    text-align: left;
    @media #{$breakResponsive} {
      text-align: center;
    }
  }
  .filter-form__label {
    @include font($font-family-main,1em,$normal);
    color: $black;
    cursor: pointer;
  }
  .global {
    padding: 5px 10px;
    border-radius: 4em;
    color: $black;
    border: 0;
    background-color: $main-color;
    height: 30px;
  }
}