$black: #000;
$white: #FFF;
$almost-white: #EFEFEF;
$main-color: #FFDF00;
$gray: #9B9B9B;
$light-gray: #DDD;
$red: #D0021B;
$dark-gray: #4A4A4A;
$feedback-success: #7ED321;
$feedback-error: #D0021B;
$feedback-warning: #4A90E2;

$size: 16px;
// Font Family
$font-family-main: 'Avenir';
$font-family-light: 'AvenirLight';
$font-family-bold: 'AvenirBold';

// Font Weight
$light:                       300;
$normal:                      400;
$bold:                        600;

//Resolutions
$breakResponsive: "only screen and (max-width : 768px)";

/*
MIXINS
*/

//Padding Mixing
@mixin padding-left($value){
  padding-left: $value;
}
@mixin padding-right($value){
  padding-right: $value;
}
@mixin padding-top($value){
  padding-top: $value;
}
@mixin padding-bottom($value){
  padding-bottom: $value;
}
@mixin padding($value){
  padding: $value;
}
//Margin Mixing
@mixin margin-left($value){
  margin-left: $value;
}
@mixin margin-right($value){
  margin-right: $value;
}
@mixin margin-top($value){
  margin-top: $value;
}
@mixin margin-bottom($value){
  margin-bottom: $value;
}
@mixin margin($value){
  margin: $value;
}

//Font Mixing
@mixin font($typo,$size,$weight){
  font-family: $typo;
  font-size:$size;
  font-weight: $weight;
}

//Hide Responsive
.hide_responsive {
  @media #{$breakResponsive} {
    display: none;
  }
}

.show_responsive {
  display: none;
  @media #{$breakResponsive} {
    display: block;
  }
}