.box__notification {
  &.empty {
    border-color: $almost-white;
    .box__info__label {
      background-color: $almost-white;
    }
    .box__info__text {
      text-align: center;
      p {
        text-align: center;
      }
    }
  }
}