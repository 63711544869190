.ranking__header__wrapper {
  background-color: $dark-gray;
  text-align: center;
  padding: 40px 10px;
  margin-bottom: 30px;
  height: 400px;
  overflow: hidden;
  .ranking__global-title__wrapper {
    margin-bottom: 20px;
    .ranking__title {
      margin: 0 auto;
      text-align: center;
      @include font($font-family-bold, 2em, $bold);
      color: $white;
    }
  }
  .ranking__global__wrapper {
    padding: 20px 20px;
    text-align: center;
    .ranking__title__wrapper {
      border-bottom: 2px solid $main-color;
      margin-bottom: 20px;
      padding-bottom: 20px;
      .ranking__title {
        text-align: center;
        margin: 0 auto;
        @include font($font-family-bold, 1em, $bold);
        color: $main-color;
      }
    }
    .ranking__collection__global {
      padding: 10px 20px;
      .ranking-indv__global__wrapper {
        .image__wrapper {
          position: relative;
          margin-bottom: 20px;
          .indv__image__wrapper {
            width: 80px;
            height: 80px;
            border-radius: 80px;
            background-color: $white;
            border: 2px solid $white;
            overflow: hidden;
            margin: 0 auto;
            img {
              max-width: 100%;
              height: auto;
              display: block;
            }
          }
          .image__budget-number__wrapper {
            position: absolute;
            top: auto;
            bottom: -10px;
            left: calc(50% - 10px);
            overflow: hidden;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            background-color: $main-color;
            border: 1px solid $dark-gray;
            .image__budget-number {
              text-align: center;
              margin: 0 auto;
              @include font($font-family-bold, 0.7em, $bold);
              color: $black;
              display: block;
              line-height: 2em;
            }
          }
        }
        .name__wrapper {
          margin-bottom: 5px;
          .name__text {
            text-align: center;
            margin: 0 auto;
            @include font($font-family-main, 1em, $normal);
            color: $white;
          }
        }
        .points__wrapper {
          .points__text {
            text-align: center;
            margin: 0 auto;
            @include font($font-family-main, 1.4em, $normal);
            color: $white;
            img {
              vertical-align: inherit;
            }
          }
        }
      }
    }
  }
}
.ranking__collection__wrapper__selected {
  margin-bottom: 30px;
  .ranking__collection__header__wrapper {
    background-color: $dark-gray;
    padding: 20px 30px;
    text-align: center;
    .ranking__title__wrapper {
      border-bottom: 2px solid $main-color;
      margin-bottom: 20px;
      padding-bottom: 20px;
      .ranking__title {
        text-align: center;
        margin: 0 auto;
        @include font($font-family-bold, 1em, $bold);
        color: $main-color;
      }
    }
    .ranking__filter__wrapper {
      color: $black;
      border: none;
      border-radius: 40px;
      overflow: hidden;
      background: #EFEFEF url("../static/images/icono-flecha-grande.svg") no-repeat 90% center;
      outline: none;
      max-width: 230px;/*200px*/
      margin: 0 auto;
      select {
        padding: 5px 8px;
        width: 100%;
        height: 100%;
        line-height: 30px;
        border: none;
        box-shadow: none;
        background-color: transparent;
        background-image: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
      }
    }
  }
  .ranking__collection__content__wrapper {
    border: 4px solid $main-color;
    border-top: none;
    padding: 10px;
    .ranking-indv__selected__wrapper {
      height: 70px;
      line-height: 70px;
      width: 100%;
      padding: 5px 5px 5px 10px;
      border-bottom: 2px solid $gray;
    }
    > .row > .col-xs-12:last-child .ranking-indv__selected__wrapper {
      border-bottom: none;
    }
  }
  .number-position__wrapper {
    .number-position {
      text-align: left;
      @include font($font-family-main, 1.2em, $normal);
      color: $black;
      margin-bottom: 0;
    }
  }
  .image__wrapper {
    height: 60px;
    width: 60px;
    overflow: hidden;
    border-radius: 60px;
    .image__ranking {
      max-width: 100%;
      display: block;
      height: auto;
    }
  }
  .name__wrapper {
    .name__text {
      text-align: left;
      @include font($font-family-main, 1em, $normal);
      color: $black;
      margin-bottom: 0;
    }
  }
  .points__wrapper {
    display: inline-block;
    vertical-align: middle;
    .points__text {
      text-align: center;
      @include font($font-family-bold, 1.2em, $bold);
      color: $black;
      margin-bottom: 0;
      img {
        vertical-align: inherit;
      }
    }
  }
  .prize__wrapper {
    position: relative;
    display: inline-block;
    border-radius: 30px;
    background-color: $main-color;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    margin-left: 10px;
    img {
      position: absolute;
      top: 8px;
      left: 9px;
    }
    &:hover {
      .prize__tooltip__wrapper {
        display: block;
      }
    }
  }
  .prize__tooltip__wrapper {
    z-index: 1;
    position: absolute;
    width: 200px;
    top: -225px;
    left: -90px;
    //overflow: hidden;
    box-shadow: 0 0 5px #ccc;
    display: none;
    @media #{$breakResponsive} {
      left: -115px;
    }
  }
  .prize__tooltip__header {
    height: 40px;
    overflow: hidden;
    background-color: $main-color;
    padding: 10px;
    .header__title__prize {
      text-align: center;
      @include font($font-family-bold, 1em, $bold);
      color: $black;
      margin-bottom: 0;
      height: 20px;
      line-height: 20px;
    }
  }
  .prize__tooltip__content {
    height: 170px;
    //overflow: hidden;
    background-color: $white;
    margin: 0 auto;
    padding: 20px;
    position: relative;
    .image__wrapper {
      height: 120px;
      overflow: hidden;
      width: 100%;
      border-radius: 0;
      img {
        display: block;
        max-width: 100%;
        height: auto;
        position: relative;
        top: auto;
        left: auto;
      }
    }
    .prize__name {
      @include font($font-family-main, 1em, $normal);
      color: $black;
      margin-bottom: 0;
      margin-top: 5px;
      text-align: center;
      height: 20px;
      line-height: 20px;
    }
  }
  .prize__tooltip__content:after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: auto;
    bottom: -20px;
    left: calc(50% - 10px);
    border-top: 10px solid $white;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
    @media #{$breakResponsive} {
      left: calc(50% + 22px);
    }
  }
  .prize__tooltip__content:before {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: auto;
    bottom: -24px;
    left: calc(50% - 10px);
    border-top: 12px solid $almost-white;
    border-right: 12px solid transparent;
    border-bottom: 12px solid transparent;
    border-left: 12px solid transparent;
    @media #{$breakResponsive} {
      left: calc(50% + 20px);
    }
  }
}