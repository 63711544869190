/**
Avenir
 */
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  src: url("../static/fonts/AvenirLTStd-Book.otf") format('opentype');
}
@font-face {
  font-family: 'AvenirLight';
  font-style: normal;
  font-weight: 300;
  src: url("../static/fonts/AvenirLTStd-Light.otf") format('opentype');
}
@font-face {
  font-family: 'AvenirBold';
  font-style: normal;
  font-weight: 700;
  src: url("../static/fonts/AvenirLTStd-Roman.otf") format('opentype');
}

/*
Avenir-Book.ttf
Avenir-Light.ttf
Avenir-Roman.ttf
.DS_Store
AvenirLTStd-Book.otf
AvenirLTStd-Light.otf
AvenirLTStd-Roman.otf
 */