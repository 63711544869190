@import "variables";
@import "fonts";

//General Blank Pages
@import "blank_pages";
@import "front_login";
@import "complete_profile";
@import "menu";
@import "dashboard";
//team_carousel on dashboard
@import "box_team_carousel";
//box_v2
@import "box_info_v2";
//activity
@import "box_activity";
//notification
@import "box_notification";
//sidebar
@import "sidebar";
//social-room
@import "social_room";
//challenge
@import "challenge";
//profile
@import "profile";
//prize
@import "prize";
//ranking
@import "ranking";

@import "feedback_message";

@import "header";

.main__content {
  overflow: hidden;
}
//Height auto to all space
.sidebar__wrapper > div {
  margin-bottom: -100000px;
  padding-bottom: 100000px;
}

.text__featured {
  color: $main-color;
}

.bolder {
  font-family: $font-family-bold;
  font-weight: $bold;
}

.pointer {
  cursor: pointer;
}

/**
Avatar
 */
.image__avatar-wrapper.pointer {
  width: 100%;
  height: 100%;
}
.default-avatar {
  margin: 0 auto;
  padding-top: 20%;
}