.challenge-object__wrapper {
  background-color: $almost-white;
  &.empty {
    padding: 30px;
  }
  margin-bottom: 40px;
  position: relative;
  .challenge__image__wrapper {
    margin-bottom: 10px;
  }
  .challenge__text__wrapper {
    text-align: center;
    .text__main {
      @include font($font-family-bold,1.6em,$bold);
      color: $black;
      margin-bottom: 5px;
    }
    .text__subtitle {
      @include font($font-family-bold,1.8em,$bold);
      color: $black;
      margin-bottom: 0;
    }
  }
  .challenge-object__label__wrapper {
    position: absolute;
    padding: 4px 20px;
    top: -10px;
    left: 15px;
    background-color: $main-color;
    &.shared {
      background-color: $light-gray;
    }
    p {
      @include font($font-family-bold,1em,$bold);
      color: $black;
      margin-bottom: 0;
    }
  }
  .challenge-object__image__wrapper {
    height: 324px;
    overflow: hidden;
    width: 39%;
    float: left;
    padding-left: 15px;
    @media #{$breakResponsive} {
      width: 100%;
      padding-right: 15px;
    }
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .challenge-object__content__wrapper {
    width: 61%;
    float: left;
    padding: 40px 45px 40px 30px;
    height: 324px;
    position: relative;
    overflow: hidden;
    @media #{$breakResponsive} {
      width: 100%;
      padding: 40px 30px;
    }
    .content__social-network__icon__wrapper {
      position: absolute;
      top: 40px;
      left: auto;
      right: 45px;
      @media #{$breakResponsive} {
        left: 30px;
        right: auto;
      }
    }
    .name__text__wrapper {
      border-bottom: 2px solid $gray;
      margin-bottom: 15px;
      @media #{$breakResponsive} {
        padding-top: 10px;
      }
      .challenge__name {
        @include font($font-family-bold,1.8em,$bold);
        color: $black;
        margin-bottom: 15px;
        line-height: 1.2em;
        max-width: 75%;
        @media #{$breakResponsive} {
          width: 100%;
        }
      }
    }
    .content__text__wrapper {
      height: 90px;
      margin-bottom: 15px;
      overflow: hidden;
      text-align: left;
      .text__paragraph {
        @include font($font-family-main,1em,$normal);
        color: $black;
        margin-bottom: 0;
        img {
          vertical-align: inherit;
        }
      }
    }
    .content__time-and-publish__wrapper {
      text-align: left;
      .time__text__wrapper {
        width: 65%;
        float: left;
        @media #{$breakResponsive} {
          text-align: center;
          width: 100%;
          float: inherit;
        }
        .date-from {
          @include font($font-family-main,1em,$normal);
          color: $black;
        }
        .date-bar {
          display: inline-block;
          width: 100px;
          height: 3px;
          overflow: hidden;
          background-color: $black;
          vertical-align: middle;
          position: relative;
          .date-bar__content {
            background-color: $main-color;
            position: absolute;
            left: 0;
            top: 0;
            height: 3px;
          }
        }
        .date-from {
          @include font($font-family-main,1em,$normal);
          color: $black;
        }
      }
      .publish-button__wrapper {
        width: 35%;
        float: left;
        @media #{$breakResponsive} {
          width: 100%;
          text-align: center;
          float: inherit;
        }
        .share-button__button {
          padding: 5px 10px;
          border-radius: 4em;
          color: $black;
          border: 0;
          background-color: $main-color;
          height: 30px;
          text-align: center;
          &.shared {
            background-color: $light-gray;
          }
          .share__button {
            color: $black;
            text-decoration: none;
            @include font($font-family-main,1em,$normal);
            &:hover,
            &:active,
            &:link,
            &:visited {
              color: $black;
            }
          }
        }
      }
    }
  }
}

.challenge-object__create__wrapper {
  max-width: 80%;
  @media #{$breakResponsive} {
    max-width: 100%;
    width: 100%;
  }
  .challenge-object__content__wrapper {
    width: 100%;
  }
  .challenge-object__preview__wrapper {
    width: 100%;
    clear: both;//Remove?
    padding: 30px 45px;
    form {
      background-color: white;
    }
    .preview__form {
      margin-bottom: 30px;
      padding: 15px 30px 0;
      .preview__form__title {
        margin-bottom: 15px;
        @include font($font-family-bold,1em,$bold);
        text-align: left;
        padding: 5px 5px 5px 15px;
        max-width: 62%;
        margin-left: -30px;
        background-color: $main-color;
        @media #{$breakResponsive} {
          max-width: 68%;
        }
      }
      textarea {
        border: 1px solid $gray;
        border-radius: 15px;
        width: 100%;
        max-width: 100%;
        height: 200px;
        max-height: 200px;
        padding: 7px 12px;
      }
    }
    .preview__example__wrapper {
      margin-bottom: 30px;
      padding: 15px 30px 0;
      .preview__form__title {
        margin-bottom: 15px;
        @include font($font-family-bold,1em,$bold);
        text-align: left;
        padding: 5px 5px 5px 15px;
        max-width: 30%;
        margin-left: -30px;
        background-color: $main-color;
      }
      .preview-example {
        border: 1px solid $gray;
        border-radius: 15px;
        padding: 30px;
        .preview-example__image__wrapper {
          width: 100%;
          height: 300px;
          overflow: hidden;
          margin-bottom: 30px;
          img {
            width: 100%;
            height: auto;
          }
        }
        .preview-example__content {
          .content__briefing {
            @include font($font-family-main,1em,$normal);
            text-align: left;
            color: $black;
            margin-bottom: 15px;
          }
          .content__hashtag {
            margin-top: 15px;
            margin-bottom: 15px;
            text-align: left;
          }
          .content__users {
            margin-bottom: 15px;
            text-align: left;
          }
          .content__link {
            margin-bottom: 15px;
            text-align: left;
            .link_el {
              @include font($font-family-main,1em,$normal);
              text-align: left;
              color: $black;
            }
          }
          .preview__title {
            @include font($font-family-bold,1.2em,$bold);
            text-align: left;
            color: $black;
          }
          .preview__cloud__wrapper {
            width: 100%;
          }
          .preview__cloud__el {
            border: 1px solid $gray;
            border-radius: 30px;
            padding: 10px 15px;
            display: inline-block;
            @include font($font-family-main,1em,$normal);
            text-align: left;
            color: $black;
          }
        }
      }
    }
    .preview-example-button__wrapper {
      padding-bottom: 40px;
      .share-button__button {
        .share__button {
          padding: 10px 15px;
          border-radius: 4em;
          color: $black;
          border: 0;
          background-color: $main-color;
        }
      }
    }
  }
}