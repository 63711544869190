#feedback__container {
  margin-left: -30px;
}
.feedback__wrapper {
  margin-left: -15px;
  margin-right: -30px;
  @media #{$breakResponsive} {
    margin-right: -15px;
  }
  padding: 15px;
  color: $white;
  text-align: center;
  position: relative;
  &.error {
    background-color: $feedback-error;
  }
  &.success {
    background-color: $feedback-success;
  }
  &.warning {
    background-color: $feedback-warning;
  }
  .feedback__title__main {
    @include font($font-family-bold,1.8em,$bold);
    margin-bottom: 5px;
    margin-top: 0;
  }
  .feedback__title__subtitle {
    @include font($font-family-main,1.2em,$normal);
    margin-bottom: 0;
  }
  .feedback__title__button {
    display: block;
    padding: 5px 10px;
    border-radius: 40px;
    color: $black;
    border: 0;
    background-color: $main-color;
    width: 230px;
    height: 40px;
    margin: 15px auto 10px;
    @include font($font-family-main,1em,$normal);
    line-height: 30px;
    margin-bottom: 0;
    text-decoration: none;
    &:link,
    &:hover,
    &:active,
    &:visited {
      color: $black;
    }
  }
  .feedback__cancel {
    position: absolute;
    top: auto;
    left: auto;
    bottom: 5px;
    right: 10px;
    #feedback__message-close {}
  }
}