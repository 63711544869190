.box__team__carousel {
  position: relative;
  .carousel-indicators {
    position: absolute;
    top: -25px;
    left: auto;
    right: 10px;
    height: 25px;
    width: auto;
    bottom: 0;
    margin: 0 auto;
    li {
      background-color: $white;
      border: 0;
      &.active {
        background-color: $black;
      }
    }
  }
  .carousel-control {
    background-image: none;
    &.left {
      span {
        top: 25%;
      }
      span:before {
        content: url("../static/images/flecha-izquierda.svg");
      }
    }
    &.right {
      span {
        top: 25%;
      }
      span:before {
        content: url("../static/images/flecha-derecha.svg");
      }
    }
  }
}